<template>
  <div class="event-disabled-indicator">
    <div class="event-disabled-indicator__icon">
      <Icon name="alert-cycle" />
    </div>
    <div class="event-disabled-indicator__text">
      Event disabled
    </div>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
};
</script>

  <style lang="scss">
  .event-disabled-indicator {
    display: flex;
    align-items: center;
    gap: 4px;
    &__icon {
      svg {
        width: 14px;
        height: 14px;
        path {
          fill: $error500;
          stroke: $white;
        };
      }
      /* color: $color-danger; */
    }
  }
  </style>
